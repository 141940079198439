import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { tryFetch } from "../../utils/api"
import { RootState } from "../store"

export interface Cpe {
    eid: string
    serialNumber: string
    lastSeen: string
}

const createInitialState = (): Array<Cpe> => {
    return []
}

export const fetchCpesList = createAsyncThunk<any, {}, { state: RootState }>(
    "commands/send",
    async (updateData: {}, { rejectWithValue }) => {
        let rsp = await tryFetch("/acs/cpes", {
            method: "GET",
        })
        let body = await rsp.json()
        if (rsp.status >= 400) {
            return rejectWithValue(body)
        }
        return body
    },
)

export const cpesSlice = createSlice({
    name: "cpes",
    initialState: createInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCpesList.pending, (state, action: any) => {})
        builder.addCase(fetchCpesList.fulfilled, (state, action) => {
            state = action.payload.response.cpes
        })
        builder.addCase(fetchCpesList.rejected, (state, action: any) => {
            state = []
        })
    },
})

export const cpesReducer = cpesSlice.reducer

export const selectCpes = (state: RootState) => state.cpes
