import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Table } from "semantic-ui-react"
import { tryFetch } from "../utils/api"
import { Cpe } from "../store/slices/cpesSlice"

const tableColumns = ["Serial Number", "Endpoint ID", "Last Seen", "Status"]

async function loadCPEs(): Promise<Cpe[]> {
    const rsp = await tryFetch("/acs/cpes")
    if (rsp.status !== 200) {
        return []
    }
    const cpeData = await rsp.json()
    return cpeData.cpes as Cpe[]
}

const CpesListView = () => {
    const [cpes, setCpes] = useState<Cpe[]>([])

    const fetchCpes = async () => {
        loadCPEs()
            .then(setCpes)
            .catch((err: any) => {
                console.error(err)
                setCpes([])
            })
    }

    useEffect(() => {
        fetchCpes()
    }, [])

    let headerCells = tableColumns.map((col, _idx) => <Table.HeaderCell>{col}</Table.HeaderCell>)

    let content
    if (cpes) {
        content = cpes.map((cpe, _idx) => (
            <Table.Row key={cpe.serialNumber}>
                <Table.Cell>
                    <Link to={`/cpes/${cpe.serialNumber}`}>{cpe.serialNumber}</Link>
                </Table.Cell>
                <Table.Cell>{cpe.eid}</Table.Cell>
                <Table.Cell>{new Date(cpe.lastSeen).toLocaleString()}</Table.Cell>
                <Table.Cell>Active</Table.Cell>
            </Table.Row>
        ))
    } else {
        content = <div>No CPE could be shown</div>
    }

    return (
        <Table>
            <Table.Header>
                <Table.Row>{headerCells}</Table.Row>
            </Table.Header>
            <Table.Body>{content}</Table.Body>
        </Table>
    )
}

export default CpesListView
